import React, { FC, ReactNode } from 'react'
import { graphql, HeadFC, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { getGatsbyImageNode } from '../../utils/getGatsbyImageNode'

import LayoutLibrary from '@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-default'

import '../styles/variables.scss'

export type LayoutProps = {
  children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      company: companyJson {
        name
        slogan
        address {
          cidade
        }
      }
      logoHorizontal: file(name: { eq: "logo-horizontal" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  const company = data.company
  const logoHorizontalImage = getImage(data.logoHorizontal)!

  return (
    <LayoutLibrary
      logoFooter={getGatsbyImageNode(
        logoHorizontalImage,
        `Logo ${company.name}`,
        true
      )}
      logoTopbar={getGatsbyImageNode(
        logoHorizontalImage,
        `Logo ${company.name}`,
        true
      )}
      companyName={company.name}
      city={company.address.cidade}
      slogan={company.slogan}
      badge={{ type: 'digital' }}
      navItems={[
        {
          label: 'Home',
          path: '/'
        },
        {
          label: 'Serviços',
          path: '/servicos'
        },
        {
          label: 'Galeria',
          path: '/galeria'
        },
        {
          label: 'Contato',
          path: '/contato'
        }
      ]}
      facebook=""
      instagram="laboratoriohct/"
      linkedin=""
    >
      {children}
    </LayoutLibrary>
  )
}

export default Layout

export const Head: HeadFC<{}> = ({}) => (
  <>
    <link
      key="nunito"
      rel="preload"
      href="/src/styles/fonts/nunito-v26-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
    <link
      key="titillium-web"
      rel="preload"
      href="/src/styles/fonts/titillium-web-v17-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
  </>
)
