import React from 'react'
import {
  GatsbyImage,
  IGatsbyImageData,
  ImageDataLike
} from 'gatsby-plugin-image'

type getGatsbyImageNodeProps = {
  image: IGatsbyImageData
  alt: string
  isContained?: boolean
  lazyLoading?: boolean
  offMain?: boolean
}

export const getGatsbyImageNode = (
  image: IGatsbyImageData,
  alt: string,
  isContained: boolean = false,
  lazyLoading: boolean = true,
  offMain: boolean = true
) => {
  return (
    <GatsbyImage
      image={image}
      alt={alt}
      loading={lazyLoading ? 'lazy' : 'eager'}
      style={{ width: '100%', height: '100%' }}
      objectFit={isContained ? 'contain' : 'cover'}
      objectPosition="center center"
      decoding={offMain ? 'async' : 'sync'}
    />
  )
}
